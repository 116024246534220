// extracted by mini-css-extract-plugin
export var lbContainerOuter = "C_tL";
export var lbContainerInner = "C_tM";
export var movingForwards = "C_tN";
export var movingForwardsOther = "C_tP";
export var movingBackwards = "C_tQ";
export var movingBackwardsOther = "C_tR";
export var lbImage = "C_tS";
export var lbOtherImage = "C_tT";
export var prevButton = "C_tV";
export var nextButton = "C_tW";
export var closing = "C_tX";
export var appear = "C_tY";