import React from 'react';
import HTMLParser from 'html-react-parser';

import SectionMedia from '../SectionMedia';
import ButtonEditor from '../../ButtonEditor/index';
import CustomSectionMedia from '../CustomSectionMedia';

import * as styles from './styles.module.css';

const StoryLayout = (props) => {
  const imageIndex = props.section.data.findIndex((elem) => elem.type === 'IMAGES/IMAGE');
  const image = props.section.data[imageIndex];
  image.content = {
    ...image.content,
    crop:
      typeof image.content.crop === 'boolean'
        ? image.content.crop
        : typeof props.section.styles.crop === 'boolean'
        ? props.section.styles.crop
        : true,
  };

  const layout = props.section.styles.layout ? props.section.styles.layout : 'left';
  const full = props.section.styles.full === true;

  const isFull = layout === 'fullLeft' || layout === 'fullRight';

  let leftWrapper = 'storyLeftWrapper';
  let rightWrapper = image.content.icon ? '' : styles.storyRightWrapperCenter;
  let contentWrapper = '';
  let headingStyle = 'storyHeader';
  let paragraphStyle = '';
  let btnStyle = 'storyBtnWrapper';
  let colLeft;
  let colRight;
  let rowWrapper = '';

  if (layout === 'left') {
    colLeft = 'col-12 col-md-7 second';
    colRight = 'col-12 col-md-5 first';
  } else if (layout === 'center') {
    leftWrapper = 'storyLeftWrapperCenter';
    headingStyle = 'storyHeaderCenter';
    paragraphStyle = 'storyParagraphCenter';
    btnStyle = 'storyBtnWrapperCenter';
    colLeft = 'col-12 col-md-8';
    colRight = 'col-12 col-md-5';
    rowWrapper = styles.storyRowWrapperCenter;
  } else if (layout === 'right') {
    colLeft = 'col-12 col-md-7';
    colRight = 'col-12 col-md-5';
    rowWrapper = styles.storyRowWrapper;
  } else if (layout === 'fullLeft') {
    colLeft = 'col-12 col-md-6';
    colRight = 'col-12 col-md-6';
    leftWrapper = 'storyWrapperFullLeft';
    rightWrapper = 'storyWrapperFull';
    contentWrapper = 'contentWrapper';
  } else if (layout === 'fullRight') {
    colLeft = 'col-12 col-md-6';
    colRight = 'col-12 col-md-6';
    leftWrapper = 'storyWrapperFullLeft';
    rightWrapper = 'storyWrapperFull';
    rowWrapper = styles.storyRowWrapper;
    contentWrapper = 'contentWrapper';
  }

  let firstActive = false;
  let secondActive = false;
  const left = [];
  let imageElement = null;
  props.section.data.map((item, index) => {
    if (item.type !== 'IMAGES/IMAGE' && item.active) {
      let result;
      if (item.type === 'BUTTONS/BUTTON_SECONDARY') {
        result = (
          <div key={`${props.section._id}_layout_${index}`} className={styles[btnStyle]}>
            <ButtonEditor
              buttons={props.buttons}
              themeData={props.themeData}
              data={item.content}
              pagePathList={props.pagePathList}
              articlePathList={props.articlePathList}
              filePathList={props.filePathList}
              categoryPathList={props.categoryPathList}
            />
          </div>
        );
      } else {
        let text;
        let type;
        if (item.type.startsWith('HEADINGS')) {
          type = 'heading';
          if (item.type === 'HEADINGS/HEADING-TWO') {
            text = `<h2>${item.text}</h2>`;
            firstActive = true;
          } else if (item.type === 'HEADINGS/HEADING-FOUR') {
            text = `<h4>${item.text}</h4>`;
            secondActive = true;
          }
        } else if (item.type.startsWith('PARAGRAPH')) {
          text = `<span>${item.text}</span>`;
          type = 'paragraph';
        }

        if (text !== undefined) {
          result = (
            <div
              key={`${props.section._id}_layout_${index}`}
              className={`${type === 'heading' ? styles[headingStyle] : styles[paragraphStyle]} ${
                isFull ? 'storyFull' : ''
              }`}
            >
              {HTMLParser(text)}
            </div>
          );
        }
      }

      left.push(result);
    } else if (item.active && item.type === 'IMAGES/IMAGE') {
      imageElement = isFull ? (
        <SectionMedia
          mediaType={item.content.icon ? 'ICON' : 'IMAGE'}
          wrapperStyleName="imageContent4"
          imageStyleName="imageFull"
          iconStyleName=""
          src={item.content.src}
          alt={item.content.alt}
          data={item.content}
          images={props.images}
          pagePathList={props.pagePathList}
          articlePathList={props.articlePathList}
          filePathList={props.filePathList}
          categoryPathList={props.categoryPathList}
          colors={props.themeData.colors}
        />
      ) : (
        <CustomSectionMedia
          data={{
            crop: props.section.styles.crop !== undefined ? props.section.styles.crop : true,
            ...item.content,
          }}
          images={props.images}
          pagePathList={props.pagePathList}
          articlePathList={props.articlePathList}
          filePathList={props.filePathList}
          categoryPathList={props.categoryPathList}
          colors={props.themeData.colors}
        />
      );
    }

    return null;
  });

  if (isFull === true && firstActive && secondActive) {
    const tmp = left[1];
    // eslint-disable-next-line prefer-destructuring
    left[1] = left[0];
    left[0] = tmp;
  }

  return (
    <div className={isFull && full ? undefined : 'container'}>
      <div className={`row ${rowWrapper}`} style={{ margin: 0 }}>
        <div className={`${colRight} ${styles[rightWrapper]}`}>{imageElement}</div>
        <div className={`${colLeft} ${styles[leftWrapper]}`}>
          <div className={styles[contentWrapper]}>{left}</div>
        </div>
      </div>
    </div>
  );
};

export default StoryLayout;
