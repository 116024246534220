// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "q_qv d_bz";
export var datasheetRowWrapper = "q_lx d_lx d_bG";
export var datasheetLeftWrapper = "q_qw d_bw d_bL";
export var datasheetWrapperFull = "q_qx d_cz";
export var datasheetWrapperFullLeft = "q_nK d_cz d_bw d_bL d_bz d_bQ";
export var contentWrapper = "q_mC d_ly";
export var contentWrapperCenter = "q_qy d_lD";
export var datasheetLeftWrapperCenter = "q_qz d_lB d_bw d_bz d_bL d_bF";
export var datasheetImageCenterWrapper = "q_lv d_lv d_s d_cr";
export var datasheetRightWrapperCenter = "q_qB d_lz";
export var datasheetFooter = "q_lC d_lC";
export var alignLeft = "q_p5 d_dr";
export var alignCenter = "q_bL d_ds";
export var alignRight = "q_p6 d_dt";
export var datasheetInnerWrapperNewLeft = "q_qC d_fb d_L d_W d_bw d_bL d_df";
export var datasheetInnerWrapperLeft = "q_qD d_d9 d_L d_W d_bw d_bL d_b2 d_df d_c6";
export var datasheetInnerWrapperRight = "q_qF d_fb d_L d_W d_bw d_bL d_df";
export var datasheetRowWrapperReverse = "q_qG d_bG";
export var datasheetSubtitle = "q_qH d_ct";
export var tableRow = "q_qJ";
export var cell = "q_qK";
export var tableRowWrapper = "q_qL d_s d_dr";
export var tableHeadingCell = "q_qM";
export var tableHeading = "q_qN";
export var tableRowStriped = "q_qP";
export var tableRowHeading = "q_qQ";
export var dataTable = "q_qR d_s";
export var imageWrapper = "q_qS d_fc d_W";
export var imageWrapperFull = "q_qT d_D d_s d_bb d_W";
export var imageWrapperIcon = "q_qV";
export var titleMargin = "q_qW d_cp";
export var datasheetInnerInnerWrapper = "q_qX d_s";
export var hasLabels = "q_qY";
export var label = "q_qZ";
export var SmallSmall = "q_q0 z_q0 z_sq z_sN";
export var SmallNormal = "q_q1 z_q1 z_sq z_sP";
export var SmallLarge = "q_q2 z_q2 z_sq z_sL";