// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "B_fM d_fM d_bw d_bD";
export var navbarDividedRight = "B_fN d_fN d_bw";
export var menuLeft = "B_tv d_fK d_bw d_ds d_bL";
export var menuRight = "B_tw d_fK d_bw d_ds d_bL";
export var menuCenter = "B_tx d_fL d_fK d_bw d_ds d_bL d_s d_bz";
export var menuDivided = "B_n8 d_fL d_fK d_bw d_ds d_bL d_s d_bz";
export var navbarItem = "B_n9 d_bt";
export var navbarLogoItemWrapper = "B_fV d_fV d_by d_bL";
export var burgerToggle = "B_ty d_f8 d_bv d_bp d_W";
export var burgerToggleOpen = "B_tz d_f8 d_bv d_bp d_0";
export var burgerInput = "B_tB d_fZ d_t d_bv d_X d_b8 d_dh d_bq";
export var burgerLine = "B_tC d_fY d_bv d_W d_t d_bj d_cl";
export var burgerMenuLeft = "B_tD d_f3 d_f2 d_f0 d_f1 d_X d_b8 d_dj d_bp d_ds";
export var burgerMenuRight = "B_tF d_f4 d_f2 d_f0 d_f1 d_X d_b8 d_dj d_bp d_ds";
export var burgerMenuCenter = "B_tG d_f5 d_f2 d_f0 d_f1 d_X d_b8 d_dj d_bp d_ds";
export var burgerMenuDivided = "B_tH d_f3 d_f2 d_f0 d_f1 d_X d_b8 d_dj d_bp d_ds";
export var btnWrapper = "B_dZ d_cT d_cM d_bw d_bD d_s";
export var cancelBtn = "B_tJ d_bY d_cz d_dx";
export var icon = "B_p0";
export var secondary = "B_tK d_by d_bL";