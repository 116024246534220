// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_rL d_gs d_cp";
export var heroHeaderCenter = "t_gt d_gt d_cp d_ds";
export var heroHeaderRight = "t_gv d_gv d_cp d_dt";
export var heroParagraphLeft = "t_rM d_gp d_cs";
export var heroParagraphCenter = "t_gq d_gq d_cs d_ds";
export var heroParagraphRight = "t_gr d_gr d_cs d_dt";
export var heroBtnWrapperLeft = "t_rN d_dZ d_dY d_s d_bw d_bC";
export var heroBtnWrapperCenter = "t_rP d_d0 d_dY d_s d_bw d_bz";
export var heroBtnWrapperRight = "t_rQ d_d1 d_dY d_s d_bw d_bD";
export var overlayBtnWrapper = "t_rR d_gn d_X d_4 d_5 d_6 d_bk d_b8";
export var design4 = "t_rS d_gm d_X d_4 d_5 d_bk";
export var heroExceptionSmall = "t_rT z_rT";
export var heroExceptionNormal = "t_rV z_rV";
export var heroExceptionLarge = "t_rW z_rW";
export var Title1Small = "t_rX z_rX z_sq z_sr";
export var Title1Normal = "t_rY z_rY z_sq z_ss";
export var Title1Large = "t_rZ z_rZ z_sq z_st";
export var BodySmall = "t_r0 z_r0 z_sq z_sK";
export var BodyNormal = "t_r1 z_r1 z_sq z_sL";
export var BodyLarge = "t_r2 z_r2 z_sq z_sM";